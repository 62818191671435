// Import necessary libraries
import React from "react";
import whiteLogo from "../src/images/Logo/whiteLogo.png";
import {
  FaCogs,
  FaChartLine,
  FaHandsHelping,
  FaEnvelope,
} from "react-icons/fa"; // Icons from FontAwesome

// Main Landing Page Component
const App = () => {
  return (
    <div className="bg-gradient-to-r from-purple-600 to-blue-500 min-h-screen flex flex-col items-center justify-center text-white">
      {/* Header Section */}
      <header className="w-full py-10 text-center flex flex-col justify-center items-center">
        <img src={whiteLogo} alt="GrapeSoft Logo" className="w-24 mb-4" />
        <h1 className="text-6xl font-extrabold drop-shadow-lg">GrapeSoft</h1>
        <h2 className="text-2xl mt-4 font-light">
          Effective Solutions for Small Business Owners
        </h2>
      </header>

      {/* Main Features Section */}
      <main className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 w-full max-w-screen-lg py-10 px-4">
        <FeatureCard
          title="Automated Hiring"
          description="Simplify your hiring process with our automated systems."
          icon={<FaCogs />}
          color="from-yellow-500 to-orange-500"
          link="https://autohire.grapesoft.co.uk"
        />
        <FeatureCard
          title="FB Ad Tracking"
          description="Track your Facebook Ads with advanced tools."
          icon={<FaChartLine />}
          color="from-green-400 to-teal-500"
          link="#"
        />
        <FeatureCard
          title="Customer Service Automation"
          description="Automate customer support for efficient service delivery."
          icon={<FaHandsHelping />}
          color="from-pink-500 to-red-500"
          link="#"
        />
        <FeatureCard
          title="Email Campaign Automation"
          description="Run email marketing campaigns effortlessly."
          icon={<FaEnvelope />}
          color="from-purple-500 to-indigo-500"
          link="#"
        />
      </main>

      {/* Footer Section */}
      <footer className="w-full py-6 bg-gray-900 text-center">
        <p>&copy; 2024 GrapeSoft. All rights reserved.</p>
      </footer>
    </div>
  );
};

// Reusable Feature Card Component
const FeatureCard = ({ title, description, icon, color, link }) => {
  return (
    <a
      href={link}
      className={`transform hover:scale-105 transition-transform duration-300 bg-gradient-to-r ${color} text-white rounded-xl shadow-lg p-6 flex flex-col items-center justify-center hover:shadow-2xl hover:bg-opacity-90`}
    >
      <div className="text-4xl mb-4">{icon}</div>
      <h3 className="text-2xl font-semibold mb-2">{title}</h3>
      <p className="text-center">{description}</p>
    </a>
  );
};

export default App;
